<template>
  <div class="app-wrapper">
    <!-- <app-header /> -->
    <app-main class="app-main" />
    <!-- <app-footer /> -->
  </div>
</template>

<script>
import { AppMain } from './components';
// import header from '@/layout/components/header';
// import footer from '@/layout/components/footer';
export default {
  name: 'Layout',
  mixins: [],
  components: {
    // appHeader: header,
    // appFooter: footer,
    AppMain,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 客服打开新窗口
    // windowClick() {
    //   window.open(
    //     'https://kf.ruanzhongzi.com/pcKefu/?propType=1&kefuId=chaiyeshangbang  ',
    //     '_blank'
    //   );
    // },
    // 回到顶部
    goToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // 平滑滚动到顶部
      });
    },
  },
};
</script>

<style scoped lang="scss">
.app-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .app-main {
    flex-grow: 1;
  }
  .serve {
    width: 1rem;
    height: 1rem;
    position: fixed;
    right: 0.5rem;
    bottom: 0.5rem;
    z-index: 200;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .img {
    width: 0.7375rem;
    height: 0.7375rem;
    position: fixed;
    right: 1.1375rem;
    bottom: 1.1375rem;
  }
}
</style>
