import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'swiper/dist/css/swiper.min.css'
import * as globalMethod from '@/utils/params'
import 'swiper/dist/js/swiper.min'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/utils/flexible.js'
import axios from 'axios'

// import * as echarts from 'echarts'
// import china from 'echarts/map/json/china.json';
// echarts.registerMap('china', china)
// Vue.prototype.$echarts = echarts;

import '@/utils/province-data'
import '@/utils/upload'
axios.defaults.baseURL = 'https://api.canxunfuwu.com' // 设置基础URL
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI)
Object.keys(globalMethod).forEach((key) => {
  Vue.prototype[key] = globalMethod[key]
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
