import Vue from "vue"
import VueRouter from "vue-router"
import Layout from "@/layout"
Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		component: Layout,
		redirect: "/home",
		children: [
			{
				path: "/home",
				name: "Home",
				component: () => import("@/views/Home/home.vue"),
				meta: {
					title: "Home",
					index: 0,
				},
			},
			{
				path: "/shareholding",
				name: "Shareholding",
				component: () => import("@/views/Shareholding/index.vue"),
				meta: {
					title: "Shareholding",
					index: 1,
				},
			},
			{
				path: "/read",
				name: "Read",
				component: () => import("@/views/Shareholding/read.vue"),
				meta: {
					title: "Read",
					index: 1,
				},
			},
			{
				path: "/jread",
				name: "Jread",
				component: () => import("@/views/Shareholding/jread.vue"),
				meta: {
					title: "Jread",
					index: 1,
				},
			},
			{
				path: "/platform",
				name: "Platform",
				component: () => import("@/views/Platform/index.vue"),
				meta: {
					title: "Platform",
					index: 2,
				},
			},
			{
				path: "/videopre",
				name: "Videopre",
				meta: {
					title: "Videopre",
					index: 2,
				},
				component: () => import("@/views/Platform/videopre.vue"),
			},
			{
				path: "/livepre",
				name: "Livepre",
				meta: {
					title: "Livepre",
					index: 2,
				},
				component: () => import("@/views/Platform/livepre.vue"),
			},
			{
				path: "/aiNum",
				name: "AiNum",
				meta: {
					title: "aiNum",
					index: 2,
				},
				component: () => import("@/views/Platform/aiNum.vue"),
			},
			{
				path: "/graphicpre",
				name: "Graphicpre",
				meta: {
					title: "Videopre",
					index: 2,
				},
				component: () => import("@/views/Platform/graphicpre.vue"),
			},
			{
				path: "/policy",
				name: "Policy",
				meta: {
					title: "Policy",
					index: 7,
				},
				component: () => import("@/views/settle/policy.vue"),
			},
			{
				path: "/layer",
				name: "Layer",
				meta: {
					title: "Layer",
					index: 7,
				},
				component: () => import("@/views/settle/layer.vue"),
			},
			{
				path: "/consultation",
				name: "Consultation",
				meta: {
					title: "Consultation",
					index: 7,
				},
				component: () => import("@/views/settle/find/consultation.vue"),
			},
			{
				path: "/pcooperate",
				name: "Pcooperate",
				meta: {
					title: "Pcooperate",
					index: 7,
				},
				component: () => import("@/views/settle/find/pcooperate.vue"),
			},
			{
				path: "/business",
				name: "Business",
				meta: {
					title: "Business",
					index: 7,
				},
				component: () => import("@/views/settle/find/business.vue"),
			},
			{
				path: "/allplace",
				name: "Allplace",
				meta: {
					title: "Allplace",
					index: 7,
				},
				component: () => import("@/views/settle/find/allplace.vue"),
			},
			{
				path: "/finance",
				name: "Finance",
				meta: {
					title: "Finance",
					index: 7,
				},
				component: () => import("@/views/settle/find/finance.vue"),
			},
			{
				path: "/metaverse",
				name: "Metaverse",
				meta: {
					title: "Metaverse",
					index: 7,
				},
				component: () => import("@/views/settle/find/metaverse.vue"),
			},
			{
				path: "/about",
				name: "About",
				component: () => import("@/views/About/index.vue"),
				meta: {
					title: "About",
					index: 6,
				},
			},
			{
				path: "/our",
				name: "Our",
				component: () => import("@/views/Our/index.vue"),
				meta: {
					title: "Our",
					index: 8,
				},
			},
			{
				path: "/map",
				name: "Map",
				component: () => import("@/views/About/map.vue"),
				meta: { title: "Map", index: 6 },
			},
			{
				path: "/natural",
				name: "Natural",
				component: () => import("@/views/Natural/index.vue"),
				meta: { title: "Natural", index: 5 },
			},
			{
				path: "/list",
				name: "List",
				component: () => import("@/views/Natural/list.vue"),
				meta: { title: "List", index: 5 },
			},
			{
				path: "/find",
				name: "Find",
				component: () => import("@/views/Platform/find.vue"),
				meta: { title: "List", index: 2 },
			},
			{
				path: "/fileOnline",
				name: "FileOnline",
				component: () => import("@/views/Natural/fileOnline.vue"),
				meta: { title: "FileOnline", index: 5 },
			},
			{
				path: "/matching",
				name: "matching",
				component: () => import("@/views/matching/index.vue"),
				meta: { title: "matching", index: 4 },
			},
			{
				path: "/matchinglist",
				name: "matchinglist",
				component: () => import("@/views/matching/matchinglist.vue"),
				meta: { title: "matchinglist", index: 4 },
			},
			{
				path: "/comparison",
				name: "comparison",
				component: () => import("@/views/Comparison/index.vue"),
				meta: { title: "comparison", index: 3 },
			},
			{
				path: "/multiple",
				name: "multiple",
				component: () => import("@/views/Comparison/multiple.vue"),
				meta: { title: "multiple", index: 3 },
			},
			{
				path: "/ComparisonPage",
				name: "ComparisonPage",
				component: () => import("@/views/Comparison/ComparisonPage.vue"),
				meta: { title: "ComparisonPage", index: 3 },
			},
		],
	},
]

const router = new VueRouter({
	routes,
})

export default router
